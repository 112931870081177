import { graphql } from 'gatsby';
import React, { Fragment, useState } from 'react';
import ModalVideo from 'react-modal-video';
import tw, { css, styled } from 'twin.macro';

import ChevronDown from '../assets/icons/chevronDown.svg';
import Play from '../assets/icons/play.svg';
import { SliceWrapper, SliceWrapperProps } from '../core-ui/SliceWrapper';
import { RichText, Text, TextType } from '../core-ui/Text';
import { Wrapper } from '../core-ui/Wrapper';
import { getYoutubeVideoId } from '../utils/getYoutubeVideoId';
import { isLast } from '../utils/isLast';
import { Button } from './Button';
import { Img } from './Img';
import { HowWeWorkFragment } from '../__generated__/types';

type Props = Pick<SliceWrapperProps, 'last'> & {
  data: HowWeWorkFragment;
};

const ModalVideoOption = {
  autoplay: 1,
};

const Separator = styled.div`
  width: 10%;
  height: 1px;
  ${tw`bg-darkerGrey mx-24 mt-14 hidden lg:block`}
`;

const ProcessNavigation = styled.li<{ isSelected: boolean }>`
  ${tw`flex lg:flex-col px-14 md:px-0 py-14 lg:py-0 bg-white lg:bg-transparent items-center justify-start md:justify-center cursor-pointer`}

  p {
    ${tw`text-black`}
    @media only screen and (min-width: 1024px) {
      ${tw`text-white w-full`}
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:after {
    @media only screen and (min-width: 1024px) {
      background-color: #ffffff;
      content: '';
      height: 2px;
      width: ${({ isSelected }) => (isSelected ? '100%' : 0)};
      ${tw`transition-all duration-300 ease-in-out mt-8`}
    }
  }
  &:hover {
    &:after {
      @media only screen and (min-width: 1024px) {
        ${tw`lg:w-full`}
      }
    }
  }
`;

const ProcessWrapper = styled.div<{ first?: boolean; isSelected: boolean }>`
  ${tw`flex flex-col lg:flex-row items-start `}
  ${({ isSelected }) => (isSelected ? tw`relative` : tw`absolute top-0`)}
  ${({ isSelected }) => (isSelected ? tw`animate-fadeIn` : tw`opacity-0`)}
`;

const StyledVideoThumbnailWrapper = styled.div`
  ${tw`relative`}
  &:after {
    -webkit-box-shadow: inset -4px -100px 200px 12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset -4px -100px 200px 12px rgba(0, 0, 0, 0.75);
    box-shadow: inset -4px -100px 200px 12px rgba(0, 0, 0, 0.75);
    content: '';
    display: block;
    height: 110%;
    position: absolute;
    top: -10%;
    width: 100%;
  }
`;

const VideoCaption = styled(Text)`
  ${tw`pl-36 absolute w-8/12`}
  z-index: 3;
  line-height: 1;
  bottom: 12px;
  @media (min-width: 640px) {
    font-size: 2.2rem;
    line-height: 34px;
    bottom: 36px;
  }
`;

const PlayButtonContainer = styled.button`
  position: absolute;
  z-index: 4;
  height: 100%;
  width: 100%;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const DEFAULT_SELECTED_INDEX = 2;

export const HowWeWork: React.FC<Props> = ({ data, ...otherProps }) => {
  const fields = data.items ?? [];
  // TODO: Create prismic field
  const [selectedIndex, setSelectedIndex] = useState(DEFAULT_SELECTED_INDEX);
  let [isPlayingVideo, setIsPlayingVideo] = useState(false);
  let [selectedVideoUrl, setSelectedVideoUrl] = useState<string | null>(null);
  let [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let [selectedProcessTitle, setSelectedProcessTitle] = useState(
    fields?.[DEFAULT_SELECTED_INDEX].process_title,
  );
  let onClose = () => setIsPlayingVideo(false);
  let playVideo = () => setIsPlayingVideo(true);

  return (
    //   todo: get id from prismic
    <SliceWrapper
      {...otherProps}
      bg="black"
      id={data.primary?.section_id ?? 'how-we-work'}
    >
      <ModalVideo
        channel="youtube"
        isOpen={isPlayingVideo}
        youtube={ModalVideoOption}
        videoId={getYoutubeVideoId(selectedVideoUrl ?? '')}
        onClose={onClose}
      />
      <Wrapper css={tw`pt-36 pb-48 md:pt-70 md:pb-120 items-center `}>
        <Text
          type={TextType.h1}
          css={tw`text-white mb-24 md:mb-32 lg:mb-64 text-center`}
        >
          {data.primary?.section_title ?? 'How we work'}
        </Text>

        <Button
          label={`STEP #${selectedIndex + 1}:${selectedProcessTitle}`}
          theme="dark"
          fullWidth
          css={css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            ${tw`lg:hidden`}
          `}
          animateWhenHovered={false}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <ChevronDown
            css={css`
              align-self: flex-end;
              width: 1rem;
              height: 1rem;
            `}
          />
        </Button>
        <div css={tw`relative z-5`}>
          <ul
            css={[
              tw`flex flex-col lg:flex-row lg:items-center absolute lg:static w-full lg:w-auto hidden lg:flex`,
              isDropdownOpen && tw`block`,
              css`
                top: 0;
                z-index: 2;
                @media only screen and (min-width: 1024px) {
                  justify-content: stretch;
                  top: 0px;
                }
              `,
            ]}
          >
            {fields?.map((item, index) => {
              return (
                <Fragment key={item.process_title}>
                  <ProcessNavigation
                    css={css`
                      width: 100%;
                      @media only screen and (min-width: 1024px) {
                        max-width: ${100 / fields.length - 5}%;
                      }
                    `}
                    isSelected={selectedIndex === index}
                    onClick={() => {
                      setSelectedIndex(index);
                      const embedUrl = item.process_video_url?.embed_url;
                      if (embedUrl != null) {
                        setSelectedVideoUrl(embedUrl);
                      }
                      isDropdownOpen && setIsDropdownOpen(!isDropdownOpen);
                      setSelectedProcessTitle(
                        item.process_title?.toUpperCase(),
                      );
                    }}
                  >
                    <Text
                      type={TextType.caption}
                      css={tw`whitespace-pre leading-24 text-center`}
                    >{`STEP #${index + 1}:`}</Text>
                    <Text
                      type={TextType.caption}
                      css={css`
                        ${tw`whitespace-pre leading-24 text-center lg:max-w-none`}
                        max-width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      `}
                    >
                      {item.process_title?.toUpperCase()}
                    </Text>
                  </ProcessNavigation>
                  {!isLast(index, fields as Array<any>) && <Separator />}
                </Fragment>
              );
            })}
          </ul>
        </div>
        <div css={tw`mt-24 md:mt-32 lg:mt-100 relative`}>
          {fields.map((item, index) => {
            return (
              <ProcessWrapper
                key={item.process_title}
                first={index === 0}
                isSelected={selectedIndex === index}
              >
                <div css={tw`w-full lg:w-1/2 relative`}>
                  {item.process_video_url?.thumbnail_url ? (
                    <Video
                      thumbnailUrl={item.process_video_url?.thumbnail_url}
                      description={item.process_video_description}
                      playVideo={playVideo}
                    />
                  ) : (
                    <Img
                      fluid={item.process_image?.fluid}
                      fallbackImage={{
                        dimensions: item.process_image?.dimensions,
                        url: item.process_image?.url,
                      }}
                    />
                  )}
                </div>
                <div
                  css={tw`w-full lg:w-1/2 pl-0 lg:pl-48 pt-24 md:pt-32 lg:pt-0`}
                >
                  <Text type={TextType.subtitle} css={tw`mb-16 md:mb-24`}>
                    {item.process_title}
                  </Text>
                  <RichText field={item.process_description?.richText} />
                </div>
              </ProcessWrapper>
            );
          })}
        </div>
      </Wrapper>
    </SliceWrapper>
  );
};

const Video: React.FC<{
  thumbnailUrl: string;
  description?: string | null;
  playVideo: () => void;
}> = ({ thumbnailUrl, description, playVideo }) => {
  return (
    <StyledVideoThumbnailWrapper>
      <img src={thumbnailUrl.replace('hqdefault', 'maxresdefault')} />
      {description ? (
        <div css={tw`w-6/12`}>
          <VideoCaption type={TextType.subtitle}>{description}</VideoCaption>
        </div>
      ) : null}
      <PlayButtonContainer aria-label="Video Play Button" onClick={playVideo}>
        <div>
          <Play
            css={css`
              width: 36px;
              @media only screen and (min-width: 1240px) {
                width: unset;
              }
            `}
          />
        </div>
      </PlayButtonContainer>
    </StyledVideoThumbnailWrapper>
  );
};

export const query = graphql`
  fragment HowWeWork on PrismicPageDataBodyHowWeWork {
    __typename
    slice_type
    slice_label
    primary {
      section_title
      section_id
    }
    items {
      process_description {
        text
        html
        richText
      }
      process_image {
        fluid(maxHeight: 460, maxWidth: 800) {
          ...GatsbyImgixFluid
        }
        url
        dimensions {
          width
          height
        }
      }
      process_title
      process_video_description
      process_video_url {
        thumbnail_url
        embed_url
      }
    }
  }
`;
